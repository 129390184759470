<script lang="ts">
    import type { Blog } from "$docs/types"

    import { chevron_right } from "@timephy/tui-icons-svelte"
    import { Icon } from "svelte-awesome"

    /* ========================================================================================== */

    let className = ""
    export { className as class }

    /* ========================================================================================== */

    export let blogId: string
    export let blog: Blog

    let href = `/blog/${blogId}`
</script>

<a {href} aria-label={blog.article.title}>
    <div class="card flex flex-col overflow-hidden sm:flex-row-reverse {className}">
        <img
            src={blog.seo.cover_image}
            title={blog.article.title}
            alt="{blog.article.title} Cover"
            class="aspect-[4/3] h-full shrink-0 border-b border-step-050 object-cover sm:border-l"
        />
        <div class="flex flex-col justify-between gap-3 px-6 py-5 sm:pt-6">
            <div class="flex flex-col items-start gap-3">
                <p
                    class="rounded-xl border border-step-150 bg-step-100 px-2 py-1 text-xs text-step-500"
                >
                    {blog.meta.topic.toUpperCase()}
                </p>
                <h3 class="line-clamp-2 text-xl font-medium">{blog.article.title}</h3>
                <div class="line-clamp-4 text-sm text-step-500">
                    {blog.article.first_section}
                </div>
            </div>
            <div
                class="flex items-center gap-1.5 text-blue-000 hover:text-blue-100 active:text-blue-200"
            >
                <p class="line-clamp-1 text-sm">{blog.meta.see_more}</p>
                <Icon data={chevron_right} class="h-4 w-4 shrink-0" />
            </div>
        </div>
    </div>
</a>
